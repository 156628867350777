
import { Component, Vue } from 'vue-property-decorator';
import Top3Incidents from '@/components/Analysis/Dashboards/common/Top3Incidents.vue';
import IncidentView from '@/components/IncidentView.vue';
import {mapGetters} from 'vuex';
import {sumShape, formatFederalDistrict, numberWithCommas} from '@/common/helpers';
import DoughnutWithLegend from '@/components/Analysis/DoughnutWithLegend.vue';
import BarBig from '@/components/Analysis/BarBig.vue';
import {FETCH_COORDINATES} from '@/store/actions.type';
import { i18n } from '@/translations';
import GoogleMap from '@/components/GoogleMap.vue';


@Component({
  components: { BarBig, Top3Incidents, IncidentView, DoughnutWithLegend,
                GoogleMap },
  computed: mapGetters(['summary', 'sByMonth', 'damageChange', 'isDamageIncreased',
                        'countChange', 'isCountIncreased', 'sByFederalDistrict',
                        'sByCargoCategory', 'coordinates', 'refs']),
})
export default class DamageDashboard extends Vue {
  private sByMonth!: any;
  private summary!: any;
  private damageChange: any;
  private isDamageIncreased: any;
  private countChange: any;
  private isCountIncreased: any;
  private sByFederalDistrict!: any;
  private sByCargoCategory!: any;
  private coordinates!: any;
  private refs!: any;
  private showIncidentView: boolean = false;

  get barByMonth() {
    const result = [];
    let maxSum = 0;
    for (const item of this.sByMonth) {
      if (item.value.sum > maxSum) {
        maxSum = item.value.sum;
      }
    }
    for (const item of this.sByMonth) {
      result.push({month: item.label[this.$i18n.locale].slice(0, 3), count: item.value.count,
        sum: sumShape(item.value.sum), ratio: item.value.sum * 100. / maxSum});
    }
    return result;
  }

  get damageSum() {
    return sumShape(this.summary.damage);
  }

  get damageAvg() {
    return sumShape(this.summary.damage_avg);
  }

  get damageByFederalDistrictFormatted() {
    let counter = 1;
    const result = this.sByFederalDistrict.map((item: any) => {
      const tmp = { ...item };
      tmp.label = formatFederalDistrict(tmp.label, this.$i18n);
      tmp.damage_raw = tmp.value[1];
      tmp.damage = numberWithCommas(tmp.value[1]);
      tmp.countChange = (tmp.value[2] === null) ? null : tmp.value[0] - tmp.value[2];
      return tmp;
    });
    result.sort((a: any, b: any) => (a.damage_raw < b.damage_raw));
    for (const item of result) {
      item.counter = counter;
      counter += 1;
    }
    return result;
  }

  get CargoCategoryByDamage() {
    const result = {labels: [], mode: 'damage_incidents', data: [], incidents: []};
    result.labels = this.sByCargoCategory.labels;
    result.data = this.sByCargoCategory.data.map((item: any) => {
      return item.d;
    });
    result.incidents = this.sByCargoCategory.data.map((item: any) => {
      return item.c;
    });
    return result;
  }

  get avgIncident() {
    const formattedIncidents: any = [...this.coordinates];
    formattedIncidents.sort((a: any, b: any) => {
      const fValue: number = a.cargo_price_rub ? a.cargo_price_rub : 0;
      const sValue: number = b.cargo_price_rub ? b.cargo_price_rub : 0;
      const fDiff: number = Math.abs(this.summary.damage_avg - fValue);
      const sDiff: number = Math.abs(this.summary.damage_avg - sValue);
      return fDiff - sDiff;
    });
    const formattedAvgIncident: any = this.formatAvgIncident(formattedIncidents[0]);
    return formattedAvgIncident;
  }

  get markers() {
    const markers: any = this.avgIncident.position ?
                         [this.avgIncident] : [];
    return markers;
  }

  private formatAvgIncident(item: any) {
    const incident: any = {location: null,
                           price: null,
                           path: null,
                           cargoCategory: null,
                           incidentCategory: null,
                           attackMethod: null,
                           typeOfScene: null,
                           ...item};
    if (item) {
      const location: string = this.getLocation(item);
      if (location) {
        incident.location = location;
      }
      if (item.cargo_price_rub) {
        incident.price = `${numberWithCommas(item.cargo_price_rub)} ₽`;
      }
      if (item.cargo_category_id) {
        incident.cargoCategory = this.refs.cargo_category[item.cargo_category_id]
                                                         [`name_${i18n.locale}`];
      } else {
        incident.cargoCategory = this.$t('strings.undefined');
      }
      if (item.attack_method) {
        incident.attackMethod = this.refs.attack_method[item.attack_method]
                                                       [`name_${i18n.locale}`];
      } else {
        incident.attackMethod = this.$t('strings.undefined');
      }
      if (item.incident_category_id) {
        incident.incidentCategory = this.refs.incident_category[item.incident_category_id]
                                                               [`name_${i18n.locale}`];
      } else {
        incident.incidentCategory = this.$t('strings.undefined');
      }
      if (item.type_of_scene) {
        incident.typeOfScene = item.type_of_scene[`name_${i18n.locale}`];
      } else {
        incident.typeOfScene = this.$t('strings.undefined');
      }
      if (item.point_of_departure && item.destination) {
        incident.path = [item.point_of_departure[`name_${i18n.locale}`],
                         item.destination[`name_${i18n.locale}`]];
      }
    }
    return incident;
  }

  private getLocation(item: any) {
    let districtId: number = 0;
    let districtName: string = '';
    let location: any;
    let region: any;
    const cityName: any = item.city[`name_${i18n.locale}`];
    if ('region_id' in item && item.region_id) {
      region = this.refs.region[item.region_id];
      if ('federal_district' in region) {
        districtId = region.federal_district;
      }
    }
    if (districtId) {
      const fDistrict: any = this.refs.federal_district[districtId];
      const prefix: string = this.$i18n.locale === 'ru' ? 'ФО' : 'FD';
      const localeKey: string = `name_${i18n.locale}`;
      const newLabel = (fDistrict[localeKey].indexOf(' ') > -1) ?
                       fDistrict[localeKey].split(' ') :
                       fDistrict[localeKey].split('-');
      districtName = `${newLabel.map((word: any) => word[0]).join('')}${prefix}`;
    }
    const locationParts = [districtName, cityName].filter( (item: any) => {
      if (item) {
        return item;
      }
    });
    if (!districtName && !cityName) {
      location = this.$t('strings.undefinedLocation');
    } else {
      location = locationParts.join(', ');
    }
    return location;
  }

  private async mounted() {
    await this.$store.dispatch(FETCH_COORDINATES);
  }

  private showIncident(incidentId: number) {
    const incidentView: any = this.$refs.incidentView;
    incidentView.loadIncident(incidentId);
    this.showIncidentView = true;
  }
}
