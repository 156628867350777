
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {sumShape, convertDayMonth, numberWithCommas} from '@/common/helpers';
import CustomBarChart from '@/components/Analysis/Dashboards/CustomBarChart.vue';
import IncidentsBarChart from '@/components/Analysis/Dashboards/common/IncidentsBarChart.vue';
import Top3Incidents from '@/components/Analysis/Dashboards/common/Top3Incidents.vue';
import DoughnutWithLegend from '@/components/Analysis/DoughnutWithLegend.vue';
import IncidentView from '@/components/IncidentView.vue';
import {FETCH_CARGO_CATEGORY_DETAILS, FETCH_COORDINATES} from '@/store/actions.type';
import { DoughnutChart } from '@/components/charts';
import GoogleMap from '@/components/GoogleMap.vue';
import { CHANGE_CURRENT_DAMAGE_BAR_CHUNK } from '@/store/mutations.type';

@Component({
  components: {CustomBarChart, Top3Incidents, IncidentsBarChart, IncidentView,
               DoughnutWithLegend, DoughnutChart, GoogleMap},
  computed: mapGetters(['summary', 'incidentsCategoryStats', 'topThreeIncidents',
                        'damageChange', 'isDamageIncreased', 'sByCargoCategory',
                        'lastIncidents', 'cargoCategoryDetails', 'sByAttackMethod',
                        'isLoading', 'coordinates', 'fieldRefs', 'loadingCoordinates',
                        'currentDamageBarChunk']),
})
export default class CargoDashboard extends Vue {

  private summary!: any;
  private damageChange: any;
  private lastIncidents!: any;
  private isDamageIncreased: any;
  private sByCargoCategory!: any;
  private showIncidentView: boolean = false;
  private cargoCategoryDetails!: any;
  private sByAttackMethod!: any;
  private isLoading!: boolean;
  private coordinates!: any;
  private fieldRefs!: any;
  private loadingCoordinates!: any;
  private initLoad = false;
  private startZoom = 8;
  private focusCoords = [55.75, 37.61];
  private currentDamageBarChunk!: number;

  get markers() {
    return this.coordinates.filter((item: any) => item.position.lat && item.position.lng)
      .map((item: any) => {
        return {
          ...item,
          position: {
            lat: (+item.position.lat) + (Math.random() - .5) / 1500,
            lng: (+item.position.lng) + (Math.random() - .5) / 1500,
          },
        };
      });
  }

  private capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  get damageSum() {
    return sumShape(this.summary.damage);
  }

  private convertDayMonth(date: string) {
    return convertDayMonth(date);
  }

  private formatDamage(damageSum: any) {
    if (damageSum) {
      return numberWithCommas(damageSum) + ' ₽';
    } else {
      return this.$i18n.t('strings.unidentified');
    }
  }

  private formatIncidentCategory(incidentCategory: any) {
    if (incidentCategory) {
      return this.capitalizeFirstLetter(incidentCategory['name_' + this.$i18n.locale]);
    } else {
      return this.$i18n.t('strings.unidentified');
    }
  }

  private changeCurrentDamageBarChunk(chunkIndex: number) {
    const res: number = (chunkIndex < this.sDamageForBarChart.length &&
                         chunkIndex > 0) ?
                        chunkIndex : 0;
    this.$store.commit(CHANGE_CURRENT_DAMAGE_BAR_CHUNK, res);
  }

  get sDamageForBarChart() {
    const chunkSize: number = 11;
    const rows: any = this.prepareStatDamageForBarChart();
    const result: any = rows.reduce((tmpList: any, item: any, itemIndex: any) => {
      const chunkIndex: number = Math.floor(itemIndex / chunkSize);
      if (!tmpList[chunkIndex]) {
        tmpList[chunkIndex] = [];
      }
      tmpList[chunkIndex].push(item);
      return tmpList;
    }, []);
    return result;
  }

  private prepareStatDamageForBarChart() {
    const result = [];
    let totalDamage = 0;
    let maxPercentage = 0;
    for (const item of this.sByCargoCategory.data) {
      totalDamage += item.d;
    }
    for (let i = 0; i < this.sByCargoCategory.labels.length; i++) {
      const percentage = this.sByCargoCategory.data[i].d / totalDamage * 100.;
      result.push({label: this.sByCargoCategory.labels[i][this.$i18n.locale],
        count: this.sByCargoCategory.data[i].c, damage: this.sByCargoCategory.data[i].d,
        damageShaped: sumShape(this.sByCargoCategory.data[i].d), ratio: percentage, barLength: 0,
        id: this.sByCargoCategory.data[i].id});
      if (percentage > maxPercentage) {
        maxPercentage = percentage;
      }
    }
    for (const item of result) {
      item.barLength = item.ratio / maxPercentage * 100;
    }
    result.sort((a, b) => (a.damage < b.damage) ? 1 : -1);
    return result;
  }

  private showIncident(incidentId: number) {
    const incidentView: any = this.$refs.incidentView;
    incidentView.loadIncident(incidentId);
    this.showIncidentView = true;
  }

  get sCargoCategoryDetails() {
    return {name: this.cargoCategoryDetails.cargo_category['name_' + this.$i18n.locale],
      totalIncidents: this.cargoCategoryDetails.total_incidents,
      count: this.cargoCategoryDetails.count, damage: numberWithCommas(this.cargoCategoryDetails.damage),
      percentageRatio: (this.cargoCategoryDetails.total_damage > 0) ?
        Math.round(this.cargoCategoryDetails.damage * 1000. / this.cargoCategoryDetails.total_damage) / 10 : 0,
      regionRatio: this.cargoCategoryDetails.attack_method_count !== null ?
          Math.round(100 * this.cargoCategoryDetails.attack_method_count /
              this.cargoCategoryDetails.count) + '%' : '',
      attackMethodRatio: this.cargoCategoryDetails.attack_method_count !== null ?
          Math.round(100 * this.cargoCategoryDetails.attack_method_count /
              this.cargoCategoryDetails.count) + '%' : '',
      typeOfSceneRatio: this.cargoCategoryDetails.type_of_scene_count !== null ?
          Math.round(100 * this.cargoCategoryDetails.type_of_scene_count /
              this.cargoCategoryDetails.count) + '%' : '',
      incidentCategoryRatio: this.cargoCategoryDetails.incident_category_count !== null ?
          Math.round(100 * this.cargoCategoryDetails.incident_category_count /
              this.cargoCategoryDetails.count) + '%' : '',
      typeOfScene: this.cargoCategoryDetails.type_of_scene !== null ?
          this.cargoCategoryDetails.type_of_scene['name_' + this.$i18n.locale] : '',
      incidentCategory: this.cargoCategoryDetails.incident_category !== null ?
          this.cargoCategoryDetails.incident_category['name_' + this.$i18n.locale] : '',
      attackMethod: this.cargoCategoryDetails.attack_method !== null ?
          this.cargoCategoryDetails.attack_method['name_' + this.$i18n.locale] : '',
      region: this.cargoCategoryDetails.region !== null ?
          this.cargoCategoryDetails.region['name_' + this.$i18n.locale] : '',
      federalDistrict: this.cargoCategoryDetails.federal_district !== null ?
          this.cargoCategoryDetails.federal_district['name_' + this.$i18n.locale] : '',
    };
  }

  get cargoCategoryChartOptions() {
    return {
      legend: {display: false},
      cutoutPercentage: 38,
      animation: {duration: 0},
      rotation: (-0.5 - 2 * (this.sCargoCategoryDetails.percentageRatio / 200)) * Math.PI,
    };
  }
  get cargoCategoryChartData() {
    return {
      labels: [this.capitalizeFirstLetter(this.sCargoCategoryDetails.name), 'Остальные'],
      datasets: [
        {
          trueValues: ['25.3%', '74.7%'],
          data: [this.sCargoCategoryDetails.percentageRatio, 100 - this.sCargoCategoryDetails.percentageRatio],
          fill: false,
          backgroundColor: ['#5856D6', '#E5E5EA'],
        },
      ],
    };
  }

  private async getCargoCategoryDetails(cargoCategoryId: number) {
    await this.$store.dispatch(FETCH_CARGO_CATEGORY_DETAILS, {cargo_category_id: cargoCategoryId});
    await this.$store.dispatch(FETCH_COORDINATES, {cargoVal: [cargoCategoryId]});
  }

  private async mounted() {
    await this.$store.dispatch(FETCH_COORDINATES, {});
    this.initLoad = true;
  }
}
