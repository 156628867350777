
import { Component, Vue, Prop } from 'vue-property-decorator';
import { numberWithCommas } from '@/common/helpers';

@Component
export default class TableStatsWidget extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private colorTitle!: string;

  @Prop()
  private headers!: string[];

  @Prop()
  private data!: any;

  private showArrows: boolean = false;  // убрать стрелочки

  private numberWithCommas(num: number) {
    return numberWithCommas(num, this.$i18n.locale);
  }
}
