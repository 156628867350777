
import {Vue, Component, Watch} from 'vue-property-decorator';
import {formatFederalDistrict, numberWithCommas} from '@/common/helpers';
import IncidentView from '@/components/IncidentView.vue';
import {mapGetters} from 'vuex';

@Component({
  computed: mapGetters(['topThreeIncidents']),
  components: {IncidentView},
})
export default class Top3Incidents extends Vue {
  private incidentsFormatted: any = [];
  private currentIncidentTop: number = 0;
  private topThreeIncidents!: any;
  private showIncidentView = false;

  private mounted() {
    this.topThreeFormatted();
    setTimeout(this.updateCurrentTopIncidentWrapper, 5000);
  }

  private updateCurrentTopIncidentWrapper() {
    setInterval(this.updateCurrentTopIncident, 5000);
  }

  private updateCurrentTopIncident() {
    const topIncidentsCount: number = this.incidentsFormatted.length;
    if (topIncidentsCount > 1) {
      const maxAllowedPosition: number = topIncidentsCount - 1;
      const newIncidentTop: number = this.currentIncidentTop > maxAllowedPosition - 1 ?
                                     0 :
                                     this.currentIncidentTop + 1;
      this.switchTopIncident(newIncidentTop);
    }
  }

  @Watch('topThreeIncidents', { deep: true })
  private topThreeFormatted() {
    this.incidentsFormatted = this.topThreeIncidents.map((incident: any) => {
      const tmp = { ...incident };
      tmp.federal_district = formatFederalDistrict(tmp.federal_district, this.$i18n);
      ['region', 'city', 'incident_category'].map((field: any) => {
        tmp[field] = tmp[field][this.$i18n.locale];
      });
      tmp.cargo_price_rub = tmp.cargo_price_rub ? numberWithCommas(tmp.cargo_price_rub) : '';
      return tmp;
    });
  }

  private switchTopIncident(position: number) {
    const positionDiff = position - this.currentIncidentTop;
    switch (positionDiff) {
      case 2: {
        const first = this.incidentsFormatted.shift();
        this.incidentsFormatted = this.incidentsFormatted.concat(first);
        break;
      }
      case 1: {
        const first = this.incidentsFormatted.shift();
        this.incidentsFormatted = this.incidentsFormatted.concat(first);
        break;
      }
      case -2: {
        const last = this.incidentsFormatted.pop();
        this.incidentsFormatted = [last].concat(this.incidentsFormatted);
        break;
      }
      case -1: {
        const last = this.incidentsFormatted.pop();
        this.incidentsFormatted = [last].concat(this.incidentsFormatted);
        break;
      }
    }
    this.currentIncidentTop = position;
  }

  private showIncident(incidentId: number) {
    const incidentView: any = this.$refs.incidentView;
    incidentView.loadIncident(incidentId);
    this.showIncidentView = true;
  }
}
