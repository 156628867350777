
import { Component, Vue } from 'vue-property-decorator';
import BarBig from '@/components/Analysis/BarBig.vue';
import DoughnutWithLegend from '@/components/Analysis/DoughnutWithLegend.vue';
import { mapGetters } from 'vuex';
import GoogleMap from '@/components/GoogleMap.vue';
import { capitalize, propI18n, sumShape } from '@/common/helpers';
import { markerIcons } from '@/common/markerIcons';
import { FETCH_COORDINATES } from '@/store/actions.type';
import CustomBarChart from '@/components/Analysis/Dashboards/CustomBarChart.vue';
import { mixins } from 'vue-class-component';
import IncidentsMapMixin from '@/mixins/IncidentsMapMixin';
import HorizontalBarChart from '@/components/charts/HorizontalBarChart.vue';
import IncidentView from '@/components/IncidentView.vue';
import ApiService from '@/common/api.service';

@Component({
  components: {
    IncidentView, GoogleMap, BarBig,
                DoughnutWithLegend, CustomBarChart,
                HorizontalBarChart },
  computed: mapGetters(['sByAttackMethod', 'isLoading', 'sByMonth', 'topTenIncByCity', 'topTenIncByRegion']),
})
export default class GeoDashboard extends mixins(IncidentsMapMixin) {
  private sByAttackMethod!: any;
  private sByMonth!: any;
  private topTenIncByCity!: any;
  private topTenIncByRegion!: any;
  private initLoad = false;
  private isLoading!: boolean;

  private showIncidentView: boolean = false;

  private selectedDistrict: any = [1];  // выбираем только ЦФО

  private districtStats: any = {};

  private async mounted() {
    await this.$store.dispatch(FETCH_COORDINATES, {});
    this.initLoad = true;
    await this.fetchDistrictStats();
  }

  private async fetchDistrictStats() {
    const resp: any = await ApiService.query('impact/incident/get_district_statistics',
      { federal_districts: this.selectedDistrict });
    this.districtStats = { ...resp.data };
  }

  get formattedDistrictStats() {
    const tmp = { ...this.districtStats };
    if (!Object.keys(this.districtStats).length) {
      return null;
    }
    ['attack_method', 'cargo_category', 'incident_category', 'type_of_scene'].map((field: any) => {
      tmp[field].name = capitalize(propI18n(this.filterRefs[field][tmp[field].id], this.$i18n));
    });
    tmp.price = sumShape(tmp.price);
    return tmp;
  }

  get topTenIncByRegionFormatted() {
    return this.topTenIncByRegion.map( (item: any) => {
      const tmp = { ...item };
      tmp.name = tmp[this.$i18n.locale];
      return tmp;
    });
  }


  get sByMonthFormatted() {
    return this.sByMonth.map((item: any) => {
      const tmp = { ...item };
      tmp.label = tmp.label[this.$i18n.locale].substr(0, 3);
      tmp.tooltip = `${this.$tc('strings.incident', tmp.value.count)}<br>${sumShape(tmp.value.sum)} ₽`;
      tmp.value = tmp.value.sum;
      return tmp;
    });
  }

  private showIncident(incidentId: number) {
    const incidentView: any = this.$refs.incidentView;
    incidentView.loadIncident(incidentId);
    this.showIncidentView = true;
  }
}
