
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DoughnutWithLegend from '@/components/Analysis/DoughnutWithLegend.vue';
import BarSmall from '@/components/Analysis/Dashboards/BarSmall.vue';
import Top3Incidents from '@/components/Analysis/Dashboards/common/Top3Incidents.vue';
import IncidentsBarChart from '@/components/Analysis/Dashboards/common/IncidentsBarChart.vue';
import TableStatsWidget from '@/components/Analysis/TableStatsWidget.vue';
import { sumShape } from '@/common/helpers';


@Component({
  components: {TableStatsWidget, IncidentsBarChart, BarSmall, DoughnutWithLegend, Top3Incidents},
  computed: mapGetters(['summary', 'sByAttackMethod', 'sByTypeOfScene', 'sByIncidentCategory', 'sByFederalDistrict',
    'incidentsCategoryStats', 'damageChange', 'isDamageIncreased']),
})
export default class IncidentsDashboard extends Vue {
  private sByAttackMethod!: any;
  private sByTypeOfScene!: any;
  private incidentsCategoryStats!: any;
  private summary!: any;
  private damageChange: any;
  private isDamageIncreased: any;

  get formattedAttackMethodStats() {
    return this.incidentsCategoryStats.attack_method.map((item: any) => {
      const tmp = [...item];
      tmp[0] = tmp[0][this.$i18n.locale];
      return tmp;
    });
  }

  get formattedRobberyStats() {
    return this.incidentsCategoryStats.incidents_category.robbery.map((item: any) => {
      const tmp = [...item];
      tmp[0] = tmp[0][this.$i18n.locale];
      return tmp;
    });
  }

  get formattedFraudStats() {
    return this.incidentsCategoryStats.incidents_category.fraud.map((item: any) => {
      const tmp = [...item];
      tmp[0] = tmp[0][this.$i18n.locale];
      return tmp;
    });
  }

  get damageSum() {
    return sumShape(this.summary.damage);
  }
}
