
import { Component, Vue } from 'vue-property-decorator';
import ExportButton from '@/components/ExportButton.vue';
@Component({
  components: { ExportButton },
})

export default class Tabs extends Vue {

  private showFilterForm() {
    this.$emit('show-filter-form', true);
  }
  get tabs() {
    return [
      { name: 'analysisCargo', text: this.$i18n.t('strings.cargoCategories') },
      { name: 'analysisIncidents', text: this.$i18n.t('strings.typesOfIncidents') },
      { name: 'analysisGeo', text: this.$i18n.t('strings.geographyOfIncidents') },
      { name: 'analysisDamage', text: this.$i18n.t('strings.damageAmount') },
    ];
  }
}
