
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BarChart, chartColors } from '@/components/charts';

@Component({
  components: { BarChart },
})
export default class BarSmall extends Vue {
  @Prop({ default: '' })
  private title!: string;

  @Prop()
  private data!: any;

  private chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    backgroundColor: '#000000',
    scales: {
      yAxes: [{
        display: false,
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          fontSize: 11,
          fontFamily: '"futurapt_demi",sans-serif;',
          fontWeight: 'bold',
        },
      }],
      xAxes: [{
        barThickness: 12,
        gridLines: {
          display: false,
        },
        ticks: {
          fontSize: 11,
          fontFamily: '"futurapt_demi",sans-serif;',
        },
      }],
    },
    legend: {
      display: false,
      labels: {
        defaultFontFamily: '"futurapt_demi",sans-serif;',
        defaultFontSize: 11,
      },
    },
  };

  get chartData() {
    const colors = chartColors.blue;
    const preparedData = this.data.data;
    const preparedLabels = this.data.labels.map((label: any) => label[this.$i18n.locale]);
    return {
      labels: preparedLabels,
      datasets: [
        {
          data: preparedData,
          label: this.$t('strings.incidents'),
          backgroundColor: '#e5e5ea',
          hoverBackgroundColor: '#ff2d55',
          borderColor: undefined,
        },
      ],
    };
  }
}
