
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CustomBarChart extends Vue {
  @Prop()
  private title!: string;

  @Prop()
  private data!: any;

  get columnWidth() {
    return 100 / this.data.length;
  }

  get dataCount() {
    return this.data.reduce((acc: any, item: any) => acc + item.value, 0);
  }
}
