
import {Vue, Component} from 'vue-property-decorator';
import CustomBarChart from '@/components/Analysis/Dashboards/CustomBarChart.vue';
import {mapGetters} from 'vuex';
import {formatFederalDistrict, sumShape} from '@/common/helpers';

@Component({
  components: {CustomBarChart},
  computed: mapGetters(['sByFederalDistrict']),
})
export default class IncidentsBarChart extends Vue {
  private sByFederalDistrict!: any;

  get sByFederalDistrictFormatted() {
    return this.sByFederalDistrict.map((item: any) => {
      const tmp = { ...item };
      tmp.label = formatFederalDistrict(tmp.label, this.$i18n);
      tmp.tooltip = `${this.$tc('strings.incident', tmp.value[0])}<br>${sumShape(tmp.value[1])} ₽`;
      tmp.value = tmp.value[0];
      return tmp;
    });
  }
}
