import { render, staticRenderFns } from "./TableStatsWidget.vue?vue&type=template&id=23e635fe&scoped=true"
import script from "./TableStatsWidget.vue?vue&type=script&lang=ts"
export * from "./TableStatsWidget.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e635fe",
  null
  
)

export default component.exports