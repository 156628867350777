
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { formatFederalDistrict } from '@/common/helpers';

const colors: any = {
  0: '#5856d6;',
  1: '#af52de;',
  2: '#007AFF;',
  3: '#26a69a;',
  4: '#34C759;',
  5: '#FF3B30;',
  6: '#FFCC00;',
  7: '#FF9500;',
};

@Component({
  computed: mapGetters(['sByDistrictByPrice']),
})
export default class BarBig extends Vue {
  @Prop({ default: '' })
  private title!: string;

  @Prop()
  private dataset!: any;

  private selectedDists: any = ['1', '2', '3', '4', '5', '6', '7', '8'];

  private sByDistrictByPrice!: any;

  private onLegendClick(districtId: any) {
    let tmp = [];
    if (this.selectedDists.includes(districtId)) {
      tmp = this.selectedDists.filter((item: any) => item !== districtId);
    } else {
      tmp = [ ...this.selectedDists, districtId];
    }
    this.selectedDists = [...tmp.sort((a: any, b: any) => a - b)];
  }

  private getValue(districtId: any): any {
    const tmp = this.formattedDistricts.filter((item: any) => item.id === districtId);
    return tmp.length ? tmp[0] : {};
  }

  private getBlockHeight(districtId: any, value: number) {
    const height = (100 * this.getValue(districtId).values[value]) / this.colMax;
    return height < 1 ? height + 1 : height;
  }

  @Watch('sByDistrictByPrice')
  private onStatsChange() {
    this.selectedDists = Object.keys(this.sByDistrictByPrice);
  }

  get cols() {
    return [
      this.$i18n.t('strings.priceRange_0_100K'),
      this.$i18n.t('strings.priceRange_100K_300K'),
      this.$i18n.t('strings.priceRange_300K_500K'),
      this.$i18n.t('strings.priceRange_500K_1M'),
      this.$i18n.t('strings.priceRange_1M_2M'),
      this.$i18n.t('strings.priceRange_2M_3M'),
      this.$i18n.t('strings.priceRange_3M_5M'),
      this.$i18n.t('strings.priceRange_5M_10M'),
      this.$i18n.t('strings.priceRange_10M_25M'),
      this.$i18n.t('strings.priceRangeOver25M'),
    ];
  }

  get colMax() {
    const maxValues = this.selectedDists.filter((item: any) => this.sByDistrictByPrice[item]).map((selected: any) => {
      return Math.max.apply(null, Object.values(this.sByDistrictByPrice[selected].values));
    });
    return maxValues.reduce((acc: any, item: any) => acc + item, 0);
  }

  get formattedDistricts() {
    return Object.keys(this.sByDistrictByPrice).map((districtId: any, key: number) => {
      return {
        id: districtId,
        name: formatFederalDistrict(this.sByDistrictByPrice[districtId], this.$i18n),
        color: colors[key],
        values: { ...this.sByDistrictByPrice[districtId].values },
      };
    });
  }

  get priceData() {
    const values: any = [];
    Object.entries(this.sByDistrictByPrice).forEach(([key, prop]: [any, any]) => {
      Object.entries(prop.values).forEach(([index, val]) => {
        if (!values[index]) {
          values[index] = 0;
        }
        values[index] += val;
      });
    });
    const maxValue = Math.max.apply(null, values);
    return values.map((item: number) => {
      const height = Math.round((100 * item) / maxValue);
      return height < 1 ? height + 1 : height;
    });
  }
}
