
import { Component, Prop, Vue } from 'vue-property-decorator';
import { chartColors, DoughnutChart } from '@/components/charts';
import { sumShape } from '@/common/helpers';

@Component({
  components: { DoughnutChart },
})
export default class DoughnutWithLegend extends Vue {
  @Prop({ default: '' })
  private title!: string;

  @Prop()
  private data!: any;

  @Prop({ default: () => ({ position: 'bottom', columns: true, small: false })})
  private legendOptions!: string;

  @Prop()
  private size!: number;

  private legend: any = [];

  private selectedLegendItem: number | null = null;

  private options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    cutoutPercentage: 70,
    animation: {
      duration: 0,
    },
    legendCallback: (chart: any) => {
      const tmp: any = [];
      const backgroundColor = chart.data.datasets[0].backgroundColor;
      chart.data.labels.forEach((label: any, index: any) => {
        tmp.push({
          color: backgroundColor[index],
          label,
          percents: chart.data.datasets[0].trueValues[index],
        });
      });
      return tmp;
    },
    legend: {
      display: false,
      // onClick: (e: any, legendItem: any) => {
      // },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const dataset = data.datasets[0];
          return `${data.labels[tooltipItem.index]}: ${dataset.data[tooltipItem.index]}%`;
        },
      },
      enabled: false,
      /* tslint:disable-next-line */
      custom: function (tooltipModel: any) {
        // Tooltip Element
        let tooltipEl: any = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem: any) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '<thead>';

          titleLines.forEach((title: any) => {
            innerHtml += '<tr><th>' + title + '</th></tr>';
          });
          innerHtml += '</thead><tbody>';

          bodyLines.forEach((body: any, i: any) => {
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const span = '<span class="chartjs-doughnut-tooltip" style="' + style + '"></span>';
            innerHtml += '<tr><td>' + span + body + '</td></tr>';
          });
          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        // @ts-ignore
        const position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style['z-index'] = 50;
      },
    },
  };

  private selectLegendItem(key: number) {
    if (this.selectedLegendItem !== key) {
      this.selectedLegendItem = key;
    } else {
      this.selectedLegendItem = null;
    }
  }

  get chartColors() {
    const colors = Object.keys(chartColors).map((color) => chartColors[color]);
    if (this.selectedLegendItem === null) {
      return colors;
    } else {
      return colors.map((color, index) => this.selectedLegendItem === index ? color : chartColors.lightGrey);
    }
  }

  get chartData() {
    const colors = Object.keys(chartColors).map((color) => chartColors[color]);
    let preparedData = this.data.data;
    let preparedLabels = this.data.labels.map((label: any) => label[this.$i18n.locale]);
    const truePercents: any = [];
    if (preparedData.length) {
      if (preparedData.length > 10) {
        preparedData = preparedData.slice(0, 10).concat(preparedData.slice(10)
          .reduce((sum: number, elem: number) => sum + elem));
        preparedLabels = preparedLabels.slice(0, 10);
        preparedLabels.push(this.$t('strings.other'));
      }
      const count = preparedData.reduce((sum: number, elem: number) => sum + elem);
      preparedData = preparedData.map((elem: number) => {
        let val = (elem * 100 / count).toFixed(1);
        truePercents.push(val);
        if (parseFloat(val) < 1) {
          val = '1';
        }
        return val;
      });
    }
    return {
      labels: preparedLabels,
      datasets: [
        {
          trueValues: truePercents,
          data: preparedData,
          label: this.$t('strings.incidents'),
          fill: false,
          lineTension: 0,
          backgroundColor: this.chartColors,
          borderColor: '#FFFFFF',
        },
      ],
    };
  }

  get chartText() {
    if (this.data.mode === 'damage_incidents') {
      return (this.selectedLegendItem === null ?
          sumShape(this.data.data.reduce((acc: number, val: number) => acc + val, 0)) :
          sumShape(this.data.data[this.selectedLegendItem])) + ' ₽';
    } else {
      return this.selectedLegendItem === null ?
          this.data.data.reduce((acc: number, val: number) => acc + val, 0) : this.data.data[this.selectedLegendItem];
    }
  }
  get chartTextSubscript() {
    if (this.data.mode === 'damage_incidents') {
      return this.$tc('strings.incident', this.selectedLegendItem === null ?
          this.data.incidents.reduce((acc: number, val: number) => acc + val, 0) :
          this.data.incidents[this.selectedLegendItem]);
    } else {
      return this.$tc('strings.incidentWord', this.selectedLegendItem === null ?
          this.data.data.reduce((acc: number, val: number) => acc + val, 0) : this.data.data[this.selectedLegendItem]);
    }
  }

  get currentColor() {
    return this.selectedLegendItem !== null ?
      Object.keys(chartColors).map((color) => chartColors[color])[this.selectedLegendItem] : 'transparent';
  }

  private getImage() {
    (this.$refs.chart as any).getImage();
  }
}
