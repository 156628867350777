
import { Component, Vue } from 'vue-property-decorator';
import Tabs from '@/components/Analysis/Tabs.vue';
import FilterTags from '@/components/Analysis/FilterTags.vue';
import FilterForm from '@/components/Analysis/FilterForm.vue';
import CargoDashboard from '@/components/Analysis/Dashboards/CargoDashboard.vue';
import DamageDashboard from '@/components/Analysis/Dashboards/DamageDashboard.vue';
import GeoDashboard from '@/components/Analysis/Dashboards/GeoDashboard.vue';
import IncidentsDashboard from '@/components/Analysis/Dashboards/IncidentsDashboard.vue';
import { FETCH_INCIDENTS_SUMMARY, FETCH_STATISTICS } from '@/store/actions.type';



@Component({
  components: {
    FilterTags, Tabs, CargoDashboard, DamageDashboard, GeoDashboard,
    IncidentsDashboard, FilterForm },
})
export default class AnalysisPageNew extends Vue {

  private showFilter: boolean = false;
  private async mounted() {
    await this.$store.dispatch(FETCH_INCIDENTS_SUMMARY);
    await this.$store.dispatch(FETCH_STATISTICS);
  }
  get dashboard() {
    const componentName = this.$route.name ?
      `${this.$route.name.replace('analysis', '')}Dashboard` : '';
    return componentName;
  }
}
